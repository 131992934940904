<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('iot-devices.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="iotDevice.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="iotDevice.iot_device_make_id"
                    dense
                    :items="iotDeviceMakesList"
                    item-text="name"
                    item-value="id"
                    :label="$t('manufacturer_name')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="iotDevice.iot_device_model_id"
                    dense
                    :items="iotDeviceModelsList"
                    item-text="name"
                    item-value="id"
                    :label="$t('model_name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="iotDevice.data_sim_id"
                    dense
                    :items="dataSimsList"
                    item-text="msisdn"
                    item-value="id"
                    :label="$t('data_sim_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="iotDevice.imei"
                    dense
                    :label="$t('imei_*')"
                    :rules="[
                      rules.required,
                      rules.number,
                      rules.min_length(15),
                      rules.max_length(15),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="iotDevice.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :disabled="iotDevice.tenant_id ? true : false"
                    :rules="[rules.required]"
                  />
                </v-col>
                <!-- <v-divider /> -->
                <!-- Devices assigment  -->
                <template v-if="iotDevice.is_active == 1">
                  <v-col cols="12">
                    <span class="text-h3">{{ $t("device_assignments") }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="iotDevice.product"
                      clearable
                      dense
                      :loading="tenantLoading"
                      :items="['oem', 'fleet']"
                      item-text="name"
                      item-value="id"
                      :label="$t('select_product_*')"
                      @change="
                        () => {
                          iotDevice.tenant_id = null;
                          setFlespiConfig();
                        }
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="iotDevice.tenant_id"
                      clearable
                      dense
                      :loading="tenantLoading"
                      :items="tenantsByProduct"
                      item-text="name"
                      item-value="id"
                      :label="$t('select_tenant')"
                      @change="setFlespiConfig"
                    />
                  </v-col>
                </template>
                <!-- Devices assigment  -->
              </v-row>
              <!-- Flespi Config -->
              <v-row v-if="iotDevice.tenant_id">
                <v-col cols="12">
                  <span class="text-h3">{{ $t("flespi_configurations") }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="iotDevice.flespi_plugin_id"
                    clearable
                    dense
                    :loading="pluginLoading"
                    :items="pluginsList"
                    item-value="flespi_plugin_id"
                    item-text="name"
                    multiple
                    :label="$t('plugin')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="iotDevice.flespi_calc_id"
                    clearable
                    dense
                    :loading="calcLoading"
                    :items="calcsList"
                    item-value="flespi_calc_id"
                    item-text="name"
                    multiple
                    :label="$t('calc')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="iotDevice.flespi_stream_id"
                    clearable
                    dense
                    :loading="streamLoading"
                    :items="streamsList"
                    item-value="flespi_stream_id"
                    item-text="name"
                    multiple
                    :label="$t('stream')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(iotDevice)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
// import axios from "axios";

// const base_url = process.env.VUE_APP_API_BASEURL;

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      stream: [],
      plugin: [],
      isVisibleDialog: false,
      isLoadingSave: false,
      iotDevice: {},
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      iotDeviceMakesList: "iotDeviceMakes/iotDeviceMakesList",
      iotDeviceModelsList: "iotDeviceModels/listModelsByType",
      //
      dataSimsList: "dataSims/activeListDataSims",
      //
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      //
      streamLoading: "flespiStream/getIsLoading",
      streamsList: "flespiStream/getStreamsList",
      pluginLoading: "flespiPlugin/getIsLoading",
      pluginsList: "flespiPlugin/getPluginsList",
      calcLoading: "flespiCalculator/getIsLoading",
      calcsList: "flespiCalculator/getCalcsList",
    }),
    tenantsByProduct() {
      if (this.iotDevice.product) {
        if (this.iotDevice.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
  },

  watch: {
    "iotDevice.iot_device_make_id": {
      handler: async function (value) {
        this.$store.dispatch("iotDeviceModels/getModelsByType", value);
      },
    },
    isVisibleDialog: {
      async handler(newValue) {
        if (newValue) {
          await this.$store.dispatch("dataSims/list", {
            iotDevices: "iot-devices",
          });
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("iotDeviceModels/list");
  },
  methods: {
    closeForm() {
      this.callFlespiConfig();
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(iotDevice) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        // this.iotDevice.is_active = 1;
        let params = { iotDevice, filter: { ...this.filterOptions } };

        await this.$store
          .dispatch("iotDevices/add", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    setFlespiConfig(val) {
      // remove key Stream, Plugin and Calc
      delete this.iotDevice.flespi_stream_id;
      delete this.iotDevice.flespi_plugin_id;
      delete this.iotDevice.flespi_calc_id;
      if (val) {
        this.callFlespiConfig(val);
      }
    },
    async callFlespiConfig(tenant_id) {
      await this.$store.dispatch("flespiStream/list", { tenant_id });
      await this.$store.dispatch("flespiPlugin/list", { tenant_id });
      await this.$store.dispatch("flespiCalculator/list", { tenant_id });
    },
  },
};
</script>
